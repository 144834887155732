import React from "react";
import ContractComp from "../Components/Client/ClientScreen/Contracts/ContractComp";

const Contract = () => {
  return (
    <div>
      <ContractComp />
    </div>
  );
};

export default Contract;
