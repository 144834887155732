const getFacuties = {
  faculties: [
    {
      id: 5,
      first_name: "Mathesh S",
      email: "mathesh********cial@gmail.com",
      gender: "Male",
      username: "mathesh********cial@gmail.com",
      phone: "6381****88",
      otp: "927289",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: false,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "1999-07-12",
      current_place_of_residence: "India",
      lived_at_current_residence: "4",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ullamcorper eleifend ipsum, ac iaculis lectus suscipit sed. Morbi et urna a ipsum maximus rutrum vel eu erat. Ut dapibus lobortis viverra. Nullam ullamcorper quam id justo malesuada vestibulum. Donec posuere eros quis erat vestibulum, id viverra erat vulputate.",
      status: "Benched",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 2,
        address: "1131, National Colony Gnanagiri Road",
        city: "Sivakasi",
        state: "Tamil Nadu",
        pincode: 626189,
        country: "India",
      },
      kyc_info: {
        id: 2,
        aadhar_number: "48034395***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "DZFPM08***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 2,
        passport_number: "",
        passport_validity: "",
        country_of_citizenship: "",
        country_of_issue: "",
        passport_front: "",
        passport_back: "",
      },
      preference_info: {
        id: 2,
        qualification: "Software Engineer",
        year_of_experience: 4,
        skills: [
          "React",
          "Python",
          "Django",
          "Node js",
          "Amazon Web Services (AWS)",
        ],
        linkedin: "https://www.linkedin.com/in/business-coach-coimbatore/",
        hackerrank: "50",
        github: "null",
        personal_website: "null",
        language: ["English: Proficient"],
      },
      travel_info: {
        id: 2,
        travelled_to: ["India:2020:48:Site Visit:Tourist:2032-01-16"],
        travel_to_for_work: [
          "Japan:Work Onsite:6-12 months:In the next 6 months",
        ],
        relocate_for_work: "",
        willingness: "Yes",
        duration: "> 12months",
        prefered_countries: ["Japan"],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 8,
          user: "19",
          title: "React Developer",
          years_active: '2020-06-07,"Current"',
          company_name: "SRC groups",
          skills: ["JavaScript", "React", "CSS", "HTML", "Tailwind CSS"],
          location: "Coimbatore",
          description:
            "Web Developer | Passionate About Crafting Digital Experiences | Proficient in HTML, javascript, React.js, CSS, JS | Agile Web Development Enthusiast | Transforming Code into Creative Web Solutions | Lifelong Learner",
          annual_salary: "10000",
          currency: "USD",
        },
      ],
      project_details_info: [
        {
          id: 7,
          user: "16",
          project_title: "HireIn 5 market landing page ",
          role: "front end developer",
          reporting_to: "dinesh ",
          duration_of_project: "6",
          skills: ["JavaScript", "React Native", "React", "CSS", "HTML"],
          description:
            "We are looking for a skilled React Developer to join our team. As a React Developer, you will be responsible for developing user interfaces using React.js and integrating them with our back-end services. You will work closely with our design and development teams to implement innovative solutions that enhance user experience and drive business growth.\n\n",
        },
      ],
      certificate_info: [
        {
          id: 8,
          user: "19",
          course_name: "B.Tech",
          date_issued: "2024-05-07",
          skills: ["JavaScript", "HTML", "CSS", "Tailwind CSS", "React"],
          url: "http://ulangi-kanish.netlify.app",
          description: "hello world",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
        {
          id: 8,
          user: "19",
          course_name: "B.Tech",
          date_issued: "2024-05-07",
          skills: ["JavaScript", "HTML", "CSS", "Tailwind CSS", "React"],
          url: "http://ulangi-kanish.netlify.app",
          description: "hello world",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
      ],
      hire_info: null,
      education_info: [],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 9,
        key_skills: ["Python", "Django", "TensorFlow", "HTML", "React Js"],
        current_employment_status: "Part-time",
        preferred_mode_of_engagement: "Full Time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "https://ramachandran.com",
        hackerrank_url: "hackerrank.com",
        github_url: "https://github.com/Ramachandran",
      },
      rate_card_info: {
        id: 7,
        remote_hourly: "100",
        remote_monthly: "1000",
        remote_annualy: "10000",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 3,
      first_name: "chinraj p",
      email: "chinraj********@gmail.com",
      gender: "Male",
      username: "chinraj********@gmail.com",
      phone: "6381*****4",
      otp: "788938",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "2024-05-15",
      current_place_of_residence: "Hong Kong",
      lived_at_current_residence: "6",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ullamcorper eleifend ipsum, ac iaculis lectus suscipit sed. Morbi et urna a ipsum maximus rutrum vel eu erat. Ut dapibus lobortis viverra. Nullam ullamcorper quam id justo malesuada vestibulum. Donec posuere eros quis erat vestibulum, id viverra erat vulputate.",
      status: "Benched",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 1,
        address: "Thenkasi main road, rajapalayam",
        city: "Coimbatore",
        state: "Tamil Nadu",
        pincode: 626121,
        country: "India",
      },
      kyc_info: {
        id: 1,
        aadhar_number: "1234567***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "MJDGEUF***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 1,
        passport_number: "",
        passport_validity: "",
        country_of_citizenship: "",
        country_of_issue: "",
        passport_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
        passport_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
      },
      preference_info: {
        id: 1,
        qualification: "python developer",
        year_of_experience: 2,
        skills: ["Python", "JavaScript", "Django", "HTML", "CSS"],
        linkedin: "https://chinrajlinkedin.com",
        hackerrank: "76",
        github: "https://chinraj.github.com",
        personal_website: "https://sona.com",
        language: ["English: Basic"],
      },
      travel_info: {
        id: 1,
        travelled_to: ["India:2020:2:for work:h-1b:2024-05-29"],
        travel_to_for_work: [
          "Singapore:Short-term business visit:6-12 months:6 months",
        ],
        relocate_for_work: "",
        willingness: "Yes",
        duration: "6-12 months",
        prefered_countries: ["Singapore"],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 8,
          user: "19",
          title: "React Developer",
          years_active: '2020-06-07,"Current"',
          company_name: "SRC groups",
          skills: ["JavaScript", "React", "CSS", "HTML", "Tailwind CSS"],
          location: "Coimbatore",
          description:
            "Web Developer | Passionate About Crafting Digital Experiences | Proficient in HTML, javascript, React.js, CSS, JS | Agile Web Development Enthusiast | Transforming Code into Creative Web Solutions | Lifelong Learner",
          annual_salary: "10000",
          currency: "USD",
        },
      ],
      project_details_info: [
        {
          id: 1,
          user: "3",
          project_title: "Shopping App",
          role: "developer",
          reporting_to: "manager",
          duration_of_project: "3",
          skills: ["Python", "Django", "CSS", "HTML", "React Js"],
          description: "lorem ipsum",
        },
      ],
      certificate_info: [
        {
          id: 1,
          user: "3",
          course_name: "python full stack ",
          date_issued: "2024-05-31",
          skills: ["JavaScript", "HTML", "CSS", "Python", "Django"],
          url: "https://chinraj-P2kozL4aiOvD",
          description: "lorem ipsum",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 1,
          user: "3",
          degree: "BCA",
          year_of_graduation: "2023",
          university_name: "MSU",
          education_level: "",
          cgpa: "3.47",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 1,
        key_skills: ["Python", "JavaScript", "Django", "HTML", "CSS"],
        current_employment_status: "Part-time",
        preferred_mode_of_engagement: "Full Time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "https://sona.com",
        hackerrank_url: "",
        github_url: "https://chinraj.github.com",
      },
      rate_card_info: {
        id: 1,
        remote_hourly: "45",
        remote_monthly: "6000",
        remote_annualy: "65000",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 19,
      first_name: "Srinivasan T",
      email: "srinithi********san04@gmail.com",
      gender: "Male",
      username: "srinithi********san04@gmail.com",
      phone: "8610*****7",
      otp: "039976",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "2004-05-04",
      current_place_of_residence: "India",
      lived_at_current_residence: "20",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ullamcorper eleifend ipsum, ac iaculis lectus suscipit sed. Morbi et urna a ipsum maximus rutrum vel eu erat. Ut dapibus lobortis viverra. Nullam ullamcorper quam id justo malesuada vestibulum. Donec posuere eros quis erat vestibulum, id viverra erat vulputate.",
      status: "Reserved",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 9,
        address: "Trichy ,TamilNadu SMT RESIDENCY, TEACHERS COLONY,Karamadai",
        city: "Trichy",
        state: "Tamil Nadu",
        pincode: 641104,
        country: "India",
      },
      kyc_info: {
        id: 9,
        aadhar_number: "12345678***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "12345678***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 9,
        passport_number: "123456789012345",
        passport_validity: "2024-05-07",
        country_of_citizenship: "India",
        country_of_issue: "India",
        passport_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
        passport_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
      },
      preference_info: {
        id: 9,
        qualification: "React developer",
        year_of_experience: 5,
        skills: ["React", "JavaScript", "HTML", "CSS", "React Js"],
        linkedin: "https://www.linkedin.com/in/-Srinithi-06045626b/",
        hackerrank: "100",
        github: "https://github.com/Srinithi",
        personal_website: "http://Srinivasan.netlify.com",
        language: ["tamil: Native", "English: Proficient"],
      },
      travel_info: {
        id: 9,
        travelled_to: ["India:2020:20:Work:H-1B:"],
        travel_to_for_work: ["Japan:Work Onsite:3-6 months:Immediate"],
        relocate_for_work: "",
        willingness: "No",
        duration: "",
        prefered_countries: [],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 8,
          user: "19",
          title: "React Developer",
          years_active: '2020-06-07,"Current"',
          company_name: "SRC groups",
          skills: ["JavaScript", "React", "CSS", "HTML", "Tailwind CSS"],
          location: "Coimbatore",
          description:
            "Web Developer | Passionate About Crafting Digital Experiences | Proficient in HTML, javascript, React.js, CSS, JS | Agile Web Development Enthusiast | Transforming Code into Creative Web Solutions | Lifelong Learner",
          annual_salary: "10000",
          currency: "USD",
        },
      ],
      project_details_info: [
        {
          id: 8,
          user: "19",
          project_title: "HireIn 5 market landing page ",
          role: "front end developer",
          reporting_to: "dinesh ",
          duration_of_project: "17",
          skills: ["JavaScript", "HTML", "CSS", "React Native", "Tailwind CSS"],
          description:
            "Web Developer | Passionate About Crafting Digital Experiences | Proficient in HTML, javascript, React.js, CSS, JS | Agile Web Development Enthusiast | Transforming Code into Creative Web Solutions | Lifelong Learner",
        },
      ],
      certificate_info: [
        {
          id: 8,
          user: "19",
          course_name: "B.Tech",
          date_issued: "2024-05-07",
          skills: ["JavaScript", "HTML", "CSS", "Tailwind CSS", "React"],
          url: "http://ulangi-kanish.netlify.app",
          description: "hello world",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
        {
          id: 8,
          user: "19",
          course_name: "B.Tech",
          date_issued: "2024-05-07",
          skills: ["JavaScript", "HTML", "CSS", "Tailwind CSS", "React"],
          url: "http://ulangi-kanish.netlify.app",
          description: "hello world",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 8,
          user: "19",
          degree: "B.tech IT",
          year_of_graduation: "2024",
          university_name: "UCEV",
          education_level: "",
          cgpa: "9.5",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 8,
        key_skills: ["React", "JavaScript", "HTML", "CSS", "React Js"],
        current_employment_status: "Part-time",
        preferred_mode_of_engagement: "Full Time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "http://Srinivasan.netlify.com",
        hackerrank_url: "hackerrank.com",
        github_url: "https://github.com/Srinithi",
      },
      rate_card_info: {
        id: 7,
        remote_hourly: "100",
        remote_monthly: "1000",
        remote_annualy: "10000",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 21,
      first_name: "Dinesh Dk",
      email: "di********@vimix.app",
      gender: "Male",
      username: "di********@vimix.app",
      phone: "8056*****1",
      otp: "410369",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: true,
      freshers_status: true,
      interview_status: "False",
      role: "3",
      date_of_birth: "2024-04-29",
      current_place_of_residence: "India",
      lived_at_current_residence: "2",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ullamcorper eleifend ipsum, ac iaculis lectus suscipit sed. Morbi et urna a ipsum maximus rutrum vel eu erat. Ut dapibus lobortis viverra. Nullam ullamcorper quam id justo malesuada vestibulum. Donec posuere eros quis erat vestibulum, id viverra erat vulputate.",
      status: "Benched",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 11,
        address: "2/326 A KUMARAN STREET",
        city: "Coimbatore",
        state: "Tamil Nadu",
        pincode: 641302,
        country: "India",
      },
      kyc_info: {
        id: 11,
        aadhar_number: "11111***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "ADRDD78***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 11,
        passport_number: "",
        passport_validity: "",
        country_of_citizenship: "",
        country_of_issue: "",
        passport_front: "",
        passport_back: "",
      },
      preference_info: {
        id: 11,
        qualification: "React Developer",
        year_of_experience: 10,
        skills: ["React", "JavaScript", "React Js", "React Native", "Figma"],
        linkedin: "https://linkedin.com",
        hackerrank: "50",
        github: "https://github.com/john",
        personal_website: "https://test.com",
        language: ["Tamil: Native"],
      },
      travel_info: {
        id: 11,
        travelled_to: ["India:2023:10:Work:H-1B:2024-05-25"],
        travel_to_for_work: [
          "Japan:Work Onsite:3-6 months:Immediate",
          "Dubai:Short-term business visit:6-12 months:In the next 6 months",
        ],
        relocate_for_work: "",
        willingness: "Yes",
        duration: "6-12 months",
        prefered_countries: ["Japan", "Dubai"],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 8,
          user: "19",
          title: "React Developer",
          years_active: '2020-06-07,"Current"',
          company_name: "SRC groups",
          skills: ["JavaScript", "React", "CSS", "HTML", "Tailwind CSS"],
          location: "Coimbatore",
          description:
            "Web Developer | Passionate About Crafting Digital Experiences | Proficient in HTML, javascript, React.js, CSS, JS | Agile Web Development Enthusiast | Transforming Code into Creative Web Solutions | Lifelong Learner",
          annual_salary: "10000",
          currency: "USD",
        },
      ],
      project_details_info: [
        {
          id: 7,
          user: "16",
          project_title: "HireIn 5 market landing page ",
          role: "front end developer",
          reporting_to: "dinesh ",
          duration_of_project: "6",
          skills: ["JavaScript", "React Native", "React", "CSS", "HTML"],
          description:
            "We are looking for a skilled React Developer to join our team. As a React Developer, you will be responsible for developing user interfaces using React.js and integrating them with our back-end services. You will work closely with our design and development teams to implement innovative solutions that enhance user experience and drive business growth.\n\n",
        },
      ],
      certificate_info: [
        {
          id: 8,
          user: "19",
          course_name: "B.Tech",
          date_issued: "2024-05-07",
          skills: ["JavaScript", "HTML", "CSS", "Tailwind CSS", "React"],
          url: "http://ulangi-kanish.netlify.app",
          description: "hello world",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
        {
          id: 8,
          user: "19",
          course_name: "B.Tech",
          date_issued: "2024-05-07",
          skills: ["JavaScript", "HTML", "CSS", "Tailwind CSS", "React"],
          url: "http://ulangi-kanish.netlify.app",
          description: "hello world",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 10,
          user: "21",
          degree: "Bachelor of Engineering",
          year_of_graduation: "2023",
          university_name: "Test University",
          education_level: "Postgraduate Education (PG)",
          cgpa: "9.5",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 10,
        key_skills: [
          "React",
          "JavaScript",
          "React Js",
          "React Native",
          "Figma",
        ],
        current_employment_status: "Full-time",
        preferred_mode_of_engagement: "Part-time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Japan", "Singapore", "Malaysia"],
        preffered_work_timings: "10:30 to 19:30 IST",
        language: [],
        website_url: "https://test.com",
        hackerrank_url: "https://hackerrank.com",
        github_url: "https://github.com/john",
      },
      rate_card_info: {
        id: 7,
        remote_hourly: "150",
        remote_monthly: "1050",
        remote_annualy: "10050",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 20,
      first_name: "RAMACHANDRAN S",
      email: "rams********@gmail.com",
      gender: "Male",
      username: "rams********@gmail.com",
      phone: "9025*****8",
      otp: "173351",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "1980-05-20",
      current_place_of_residence: "India",
      lived_at_current_residence: "18",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ullamcorper eleifend ipsum, ac iaculis lectus suscipit sed. Morbi et urna a ipsum maximus rutrum vel eu erat. Ut dapibus lobortis viverra. Nullam ullamcorper quam id justo malesuada vestibulum. Donec posuere eros quis erat vestibulum, id viverra erat vulputate.",
      status: "Benched",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 10,
        address: "SRK NAGAR SMT RESIDENCY, TEACHERS COLONY,Karamadai",
        city: "coimbatore",
        state: "Tamil Nadu",
        pincode: 641104,
        country: "India",
      },
      kyc_info: {
        id: 10,
        aadhar_number: "123456789***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "1234567***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 10,
        passport_number: "123456789012345",
        passport_validity: "2024-05-07",
        country_of_citizenship: "India",
        country_of_issue: "India",
        passport_front: "",
        passport_back: "",
      },
      preference_info: {
        id: 10,
        qualification: "Python developer",
        year_of_experience: 5,
        skills: ["Python", "Django", "TensorFlow", "HTML", "React Js"],
        linkedin: "https://www.linkedin.com/in/ramachandran-06045626b/",
        hackerrank: "60",
        github: "https://github.com/Ramachandran",
        personal_website: "https://ramachandran.com",
        language: ["tamil: Native", "en: Proficient"],
      },
      travel_info: {
        id: 10,
        travelled_to: ["India:2020:20:Work:H-1B:"],
        travel_to_for_work: ["Japan:Work Onsite:3-6 months:Immediate"],
        relocate_for_work: "",
        willingness: "No",
        duration: "",
        prefered_countries: [],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 9,
          user: "20",
          title: "Python Developer",
          years_active: '2018-01-30,"Current"',
          company_name: "SRC groups",
          skills: ["JavaScript", "Python", "HTML", "CSS", "React Native"],
          location: "Coimbatore",
          description:
            "Web Developer | Passionate About Crafting Digital Experiences | Proficient in HTML, javascript, React.js, CSS, JS | Agile Web Development Enthusiast | Transforming Code into Creative Web Solutions | Lifelong Learner",
          annual_salary: "10000",
          currency: "USD",
        },
      ],
      project_details_info: [
        {
          id: 9,
          user: "20",
          project_title: "HireIn 5 market landing page ",
          role: "front end developer",
          reporting_to: "dinesh ",
          duration_of_project: "6",
          skills: ["JavaScript", "HTML", "CSS", "React", "Tailwind CSS"],
          description:
            "Web Developer | Passionate About Crafting Digital Experiences | Proficient in HTML, javascript, React.js, CSS, JS | Agile Web Development Enthusiast | Transforming Code into Creative Web Solutions | Lifelong Learner",
        },
      ],
      certificate_info: [
        {
          id: 8,
          user: "19",
          course_name: "B.Tech",
          date_issued: "2024-05-07",
          skills: ["JavaScript", "HTML", "CSS", "Tailwind CSS", "React"],
          url: "http://ulangi-kanish.netlify.app",
          description: "hello world",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
        {
          id: 8,
          user: "19",
          course_name: "B.Tech",
          date_issued: "2024-05-07",
          skills: ["JavaScript", "HTML", "CSS", "Tailwind CSS", "React"],
          url: "http://ulangi-kanish.netlify.app",
          description: "hello world",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 9,
          user: "20",
          degree: "B.tech IT",
          year_of_graduation: "2024",
          university_name: "UCEV",
          education_level: "",
          cgpa: "9.5",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 9,
        key_skills: ["Python", "Django", "TensorFlow", "HTML", "React Js"],
        current_employment_status: "Part-time",
        preferred_mode_of_engagement: "Full Time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "https://ramachandran.com",
        hackerrank_url: "hackerrank.com",
        github_url: "https://github.com/Ramachandran",
      },
      rate_card_info: {
        id: 7,
        remote_hourly: "100",
        remote_monthly: "1000",
        remote_annualy: "10000",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 10,
      first_name: "Priya Gupta",
      email: "santhoshsh********ial@gmail.com",
      gender: "Male",
      username: "santhoshsh********ial@gmail.com",
      phone: "9080*****5",
      otp: "219187",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "2001-01-05",
      current_place_of_residence: "372 AKP Middle Street Vishvanatham",
      lived_at_current_residence: "98",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      status: "Benched",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 3,
        address: "372 AKP Middle Street Vishvanatham",
        city: "sivakasi",
        state: "Tamil Nadu",
        pincode: 626123,
        country: "India",
      },
      kyc_info: {
        id: 3,
        aadhar_number: "123412341***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "123412341***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 3,
        passport_number: "1234567890",
        passport_validity: "2024-12-14",
        country_of_citizenship: "India",
        country_of_issue: "India",
        passport_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
        passport_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
      },
      preference_info: {
        id: 3,
        qualification: "Java Developer",
        year_of_experience: 10,
        skills: ["React", "Bootstrap", "Python", "JavaScript", "Java"],
        linkedin: "https://linkedin.com",
        hackerrank: "10",
        github: "https://github.com",
        personal_website: "https://web.com",
        language: ["English: Conversational"],
      },
      travel_info: {
        id: 3,
        travelled_to: ["Algeria:2020:10:Work:H-1B:2024-06-09"],
        travel_to_for_work: ["Japan:Work Onsite:3-6 months:Immediate"],
        relocate_for_work: "",
        willingness: "Yes",
        duration: "6-12 months",
        prefered_countries: ["Japan"],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 2,
          user: "10",
          title: "Java Developer",
          years_active: '2023-01-31,"Current"',
          company_name: "PhonePe",
          skills: ["JavaScript"],
          location: "Hyderabad, India",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          annual_salary: "300000",
          currency: "INR",
        },
      ],
      project_details_info: [
        {
          id: 3,
          user: "10",
          project_title: "HireIn5",
          role: "Lead Software Developer",
          reporting_to: "Haed of Development, CEO",
          duration_of_project: "10",
          skills: ["Java", "JavaScript"],
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
      ],
      certificate_info: [
        {
          id: 3,
          user: "10",
          course_name: "UIUX",
          date_issued: "2021-02-06",
          skills: ["Java", "JavaScript"],
          url: "www.google.com/googlecourse",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "2024-06-09",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 3,
          user: "10",
          degree: "BCA",
          year_of_graduation: "2020",
          university_name: "PSG College of Arts and Science",
          education_level: "Postgraduate Education (PG)",
          cgpa: "8",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 2,
        key_skills: ["React", "Bootstrap", "Python", "JavaScript", "Java"],
        current_employment_status: "Part-time",
        preferred_mode_of_engagement: "Full Time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "https://web.com",
        hackerrank_url: "hackerrank.com",
        github_url: "https://github.com",
      },
      rate_card_info: {
        id: 3,
        remote_hourly: "1000",
        remote_monthly: "10000",
        remote_annualy: "200000",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 11,
      first_name: "Asik K",
      email: "aham********r@gmail.com",
      gender: "Male",
      username: "aham********r@gmail.com",
      phone: "9080*****5",
      otp: "362128",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "2008-02-14",
      current_place_of_residence: "Gurumurugan nagar 3 rd street, Vishvanatham",
      lived_at_current_residence: "100",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      status: "Benched",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 4,
        address: "Gurumurugan nagar 3 rd street, Vishvanatham",
        city: "sivakasi",
        state: "Tamil Nadu",
        pincode: 626123,
        country: "India",
      },
      kyc_info: {
        id: 4,
        aadhar_number: "123412341***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "123412341***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 4,
        passport_number: "123456789",
        passport_validity: "2024-06-23",
        country_of_citizenship: "India",
        country_of_issue: "India",
        passport_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
        passport_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
      },
      preference_info: {
        id: 4,
        qualification: "Java Developer",
        year_of_experience: 12,
        skills: [
          "Data Analysis",
          "Database Management",
          "Python",
          "Java",
          "RESTful API",
        ],
        linkedin: "https://linkedin.com",
        hackerrank: "100",
        github: "https://github.com",
        personal_website: "https://web.com",
        language: ["English: Basic", "Tamil: Native"],
      },
      travel_info: {
        id: 4,
        travelled_to: ["Nigeria:2020:10:Work:H-1B:2024-06-09"],
        travel_to_for_work: [
          "Dubai:Short-term business visit:6-12 months:Immediate",
        ],
        relocate_for_work: "",
        willingness: "Yes",
        duration: "6-12 months",
        prefered_countries: ["Dubai"],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 3,
          user: "11",
          title: "Java Developer",
          years_active: '2024-01-06,"Current"',
          company_name: "PhonePe",
          skills: ["JavaScript", "Python"],
          location: "Hyderabad, India",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          annual_salary: "300000",
          currency: "INR",
        },
      ],
      project_details_info: [
        {
          id: 2,
          user: "11",
          project_title: "HireIn5",
          role: "Lead Software Developer",
          reporting_to: "Haed of Development, CEO",
          duration_of_project: "10",
          skills: ["JavaScript", "HTML"],
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
      ],
      certificate_info: [
        {
          id: 2,
          user: "11",
          course_name: "UIUX",
          date_issued: "2023-02-02",
          skills: ["Python", "JavaScript"],
          url: "www.google.com/googlecourse",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "2024-06-09",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 2,
          user: "11",
          degree: "BCA",
          year_of_graduation: "2020",
          university_name: "PSG College of Arts and Science",
          education_level: "Undergraduate Education (UG)",
          cgpa: "8",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 3,
        key_skills: [
          "Data Analysis",
          "Database Management",
          "Python",
          "Java",
          "RESTful API",
        ],
        current_employment_status: "Part-time",
        preferred_mode_of_engagement: "Full Time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "https://web.com",
        hackerrank_url: "hackerrank.com",
        github_url: "https://github.com",
      },
      rate_card_info: {
        id: 2,
        remote_hourly: "100",
        remote_monthly: "150",
        remote_annualy: "200",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 13,
      first_name: "Divya Shree",
      email: "santho********07@gmail.com",
      gender: "Male",
      username: "santho********07@gmail.com",
      phone: "9080*****5",
      otp: "440919",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "2005-02-06",
      current_place_of_residence: "Gurumurugan nagar 3 rd street, Vishvanatham",
      lived_at_current_residence: "98",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      status: "Benched",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 6,
        address: "Gurumurugan nagar 3 rd street, Vishvanatham",
        city: "sivakasi",
        state: "Tamil Nadu",
        pincode: 626123,
        country: "India",
      },
      kyc_info: {
        id: 6,
        aadhar_number: "1234123412***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "12341234***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 6,
        passport_number: "123456789",
        passport_validity: "2024-06-09",
        country_of_citizenship: "India",
        country_of_issue: "India",
        passport_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
        passport_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
      },
      preference_info: {
        id: 6,
        qualification: "Three Js Developer",
        year_of_experience: 10,
        skills: ["JavaScript", "Java", "Python", "React", "React Native"],
        linkedin: "https://linkedin.com",
        hackerrank: "10",
        github: "https://github.com",
        personal_website: "https://web.com",
        language: ["English: Proficient"],
      },
      travel_info: {
        id: 6,
        travelled_to: ["France:2020:10:Work:H-1B:2025-12-19"],
        travel_to_for_work: [
          "Japan:Work Onsite:3-6 months:In the next 6 months",
        ],
        relocate_for_work: "",
        willingness: "Yes",
        duration: "6-12 months",
        prefered_countries: ["Japan"],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 8,
          user: "19",
          title: "React Developer",
          years_active: '2020-06-07,"Current"',
          company_name: "SRC groups",
          skills: ["JavaScript", "React", "CSS", "HTML", "Tailwind CSS"],
          location: "Coimbatore",
          description:
            "Web Developer | Passionate About Crafting Digital Experiences | Proficient in HTML, javascript, React.js, CSS, JS | Agile Web Development Enthusiast | Transforming Code into Creative Web Solutions | Lifelong Learner",
          annual_salary: "10000",
          currency: "USD",
        },
      ],
      project_details_info: [
        {
          id: 5,
          user: "13",
          project_title: "HireIn5",
          role: "Lead Software Developer",
          reporting_to: "Haed of Development, CEO",
          duration_of_project: "67",
          skills: ["HTML"],
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
      ],
      certificate_info: [
        {
          id: 5,
          user: "13",
          course_name: "UIUX",
          date_issued: "2021-06-09",
          skills: ["Python", "JavaScript"],
          url: "www.google.com/googlecourse",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 5,
          user: "13",
          degree: "BCA",
          year_of_graduation: "2020",
          university_name: "PSG College of Arts and Science",
          education_level: "Postgraduate Education (PG)",
          cgpa: "8",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 5,
        key_skills: ["JavaScript", "Java", "Python", "React", "React Native"],
        current_employment_status: "Part-time",
        preferred_mode_of_engagement: "Full Time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "https://web.com",
        hackerrank_url: "hackerrank.com",
        github_url: "https://github.com",
      },
      rate_card_info: {
        id: 5,
        remote_hourly: "1000",
        remote_monthly: "20000",
        remote_annualy: "300000",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 15,
      first_name: "Vasanthu P",
      email: "vasant********th5@gmail.com",
      gender: "Male",
      username: "vasant********th5@gmail.com",
      phone: "9025*****8",
      otp: "820322",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "2002-04-17",
      current_place_of_residence: "Australia",
      lived_at_current_residence: "11",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ullamcorper eleifend ipsum, ac iaculis lectus suscipit sed. Morbi et urna a ipsum maximus rutrum vel eu erat. Ut dapibus lobortis viverra. Nullam ullamcorper quam id justo malesuada vestibulum. Donec posuere eros quis erat vestibulum, id viverra erat vulputate.",
      status: "Benched",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 7,
        address: "Ramapuram,chennai",
        city: "Ariyalur",
        state: "Tamil Nadu",
        pincode: 621719,
        country: "India",
      },
      kyc_info: {
        id: 7,
        aadhar_number: "123456***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "8764123***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 7,
        passport_number: "905487389",
        passport_validity: "2002-04-17",
        country_of_citizenship: "France",
        country_of_issue: "China",
        passport_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
        passport_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
      },
      preference_info: {
        id: 7,
        qualification: "python developer",
        year_of_experience: 0,
        skills: ["Python", "SQL", "Java", "Django", "Figma"],
        linkedin: "https://stage.hirein5.com/#/registration",
        hackerrank: "40",
        github: "https://stage.hirein5.com/#/registration",
        personal_website: "https://stage.hirein5.com/#/registration",
        language: ["English: Conversational"],
      },
      travel_info: {
        id: 7,
        travelled_to: ["India:2025:2:work:H-1B:2026-07-21"],
        travel_to_for_work: ["Dubai:Work Onsite:3-6 months:Immediate"],
        relocate_for_work: "",
        willingness: "Yes",
        duration: "6-12 months",
        prefered_countries: ["Dubai"],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 8,
          user: "19",
          title: "React Developer",
          years_active: '2020-06-07,"Current"',
          company_name: "SRC groups",
          skills: ["JavaScript", "React", "CSS", "HTML", "Tailwind CSS"],
          location: "Coimbatore",
          description:
            "Web Developer | Passionate About Crafting Digital Experiences | Proficient in HTML, javascript, React.js, CSS, JS | Agile Web Development Enthusiast | Transforming Code into Creative Web Solutions | Lifelong Learner",
          annual_salary: "10000",
          currency: "USD",
        },
      ],
      project_details_info: [
        {
          id: 6,
          user: "15",
          project_title: "Crime vision",
          role: "Developer",
          reporting_to: "HR",
          duration_of_project: "2",
          skills: ["Python"],
          description: "",
        },
      ],
      certificate_info: [
        {
          id: 6,
          user: "15",
          course_name: "Data science",
          date_issued: "2024-03-12",
          skills: ["Python"],
          url: "https://zty.pe/?load",
          description: "hi",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 6,
          user: "15",
          degree: "B.tech",
          year_of_graduation: "2024",
          university_name: "university college of engineering villupuram",
          education_level: "",
          cgpa: "9",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 6,
        key_skills: ["Python", "SQL", "Java", "Django", "Figma"],
        current_employment_status: "Full-time",
        preferred_mode_of_engagement: "Full-time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "https://stage.hirein5.com/#/registration",
        hackerrank_url: "https://stage.hirein5.com/#/registration",
        github_url: "https://stage.hirein5.com/#/registration",
      },
      rate_card_info: {
        id: 6,
        remote_hourly: "1000",
        remote_monthly: "100000",
        remote_annualy: "1000000",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 16,
      first_name: "Kaneeshvar Ramachandran",
      email: "kane********r@vimix.app",
      gender: "Male",
      username: "kane********r@vimix.app",
      phone: "9442*****1",
      otp: "276496",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "2002-02-13",
      current_place_of_residence: "India",
      lived_at_current_residence: "16",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ullamcorper eleifend ipsum, ac iaculis lectus suscipit sed. Morbi et urna a ipsum maximus rutrum vel eu erat. Ut dapibus lobortis viverra. Nullam ullamcorper quam id justo malesuada vestibulum. Donec posuere eros quis erat vestibulum, id viverra erat vulputate.",
      status: "Benched",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 8,
        address: "SRK NAGAR SMT RESIDENCY, TEACHERS COLONY,Karamadai",
        city: "coimbatore",
        state: "Tamil Nadu",
        pincode: 641104,
        country: "India",
      },
      kyc_info: {
        id: 8,
        aadhar_number: "123456789***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "12345678901***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 8,
        passport_number: "123456789012345",
        passport_validity: "2025-06-10",
        country_of_citizenship: "India",
        country_of_issue: "India",
        passport_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
        passport_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
      },
      preference_info: {
        id: 8,
        qualification: "React developer",
        year_of_experience: 4,
        skills: ["React", "React Native", "HTML", "CSS", "Tailwind CSS"],
        linkedin:
          "https://www.linkedin.com/in/kaneeshvar-ramachandran-06045626b/",
        hackerrank: "100",
        github: "https://github.com/Kaneeshvar",
        personal_website: "https://kaneeshvar.com",
        language: ["tamil: Native", "english: Proficient", ":"],
      },
      travel_info: {
        id: 8,
        travelled_to: ["India:2020:20:Work:H-1B:"],
        travel_to_for_work: ["Japan:Work Onsite:3-6 months:Immediate"],
        relocate_for_work: "",
        willingness: "No",
        duration: "",
        prefered_countries: [],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 7,
          user: "16",
          title: "React Developer",
          years_active: '2023-06-02,"Current"',
          company_name: "SRC groups",
          skills: ["JavaScript", "HTML", "CSS", "React Native", "Tailwind CSS"],
          location: "Coimbatore",
          description:
            "We are looking for a skilled React Developer to join our team. As a React Developer, you will be responsible for developing user interfaces using React.js and integrating them with our back-end services. You will work closely with our design and development teams to implement innovative solutions that enhance user experience and drive business growth.\n\n",
          annual_salary: "10000",
          currency: "INR",
        },
      ],
      project_details_info: [
        {
          id: 7,
          user: "16",
          project_title: "HireIn 5 market landing page ",
          role: "front end developer",
          reporting_to: "dinesh ",
          duration_of_project: "6",
          skills: ["JavaScript", "React Native", "React", "CSS", "HTML"],
          description:
            "We are looking for a skilled React Developer to join our team. As a React Developer, you will be responsible for developing user interfaces using React.js and integrating them with our back-end services. You will work closely with our design and development teams to implement innovative solutions that enhance user experience and drive business growth.\n\n",
        },
      ],
      certificate_info: [
        {
          id: 7,
          user: "16",
          course_name: "",
          date_issued: "",
          skills: ["JavaScript", "React", "React Native", "HTML", "CSS"],
          url: "",
          description: "hello world",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 7,
          user: "16",
          degree: "B.tech IT",
          year_of_graduation: "2024",
          university_name: "UCEV",
          education_level: "",
          cgpa: "9.5",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 7,
        key_skills: ["React", "React Native", "HTML", "CSS", "Tailwind CSS"],
        current_employment_status: "Part-time",
        preferred_mode_of_engagement: "Full Time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "https://kaneeshvar.com",
        hackerrank_url: "hackerrank.com",
        github_url: "https://github.com/Kaneeshvar",
      },
      rate_card_info: {
        id: 7,
        remote_hourly: "50",
        remote_monthly: "1000",
        remote_annualy: "10000",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
    {
      id: 12,
      first_name: "Santhosh Shivan",
      email: "codepu********ial@gmail.com",
      gender: "Male",
      username: "codepu********ial@gmail.com",
      phone: "9080*****5",
      otp: "164470",
      title: "null",
      linked_in: "",
      block_expiry: null,
      nottify: true,
      fresher: false,
      freshers_status: false,
      interview_status: "False",
      role: "3",
      date_of_birth: "2013-01-31",
      current_place_of_residence: "372 AKP Middle Street Vishvanatham",
      lived_at_current_residence: "100",
      email_verification: true,
      mobile_verification: true,
      onboarding_status: "4",
      profile_picture: "",
      part_time_availability: "",
      bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      status: "Reserved",
      apprual: true,
      employee_id: "",
      dissabled: false,
      hired_status: "",
      background_verification:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      personality_assessment:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      address: {
        id: 5,
        address: "372 AKP Middle Street Vishvanatham",
        city: "sivakasi",
        state: "Tamil Nadu",
        pincode: 626123,
        country: "India",
      },
      kyc_info: {
        id: 5,
        aadhar_number: "12341234***",
        aadhar_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
        aadhar_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
        pan_number: "123412341***",
        pan_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
      },
      passport_info: {
        id: 5,
        passport_number: "1234567890",
        passport_validity: "2024-07-07",
        country_of_citizenship: "India",
        country_of_issue: "India",
        passport_front:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
        passport_back:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
      },
      preference_info: {
        id: 5,
        qualification: "Java Developer",
        year_of_experience: 10,
        skills: ["Tailwind CSS", "JavaScript", "Java", "Python", "React"],
        linkedin: "https://linkedin.com",
        hackerrank: "10",
        github: "https://github.com",
        personal_website: "https://web.com",
        language: ["English: Native"],
      },
      travel_info: {
        id: 5,
        travelled_to: ["Afghanistan:2020:10:Work:H-1B:2024-06-09"],
        travel_to_for_work: [
          "Japan:Work Onsite:6-12 months:In the next 6 months",
        ],
        relocate_for_work: "",
        willingness: "Yes",
        duration: "6-12 months",
        prefered_countries: ["Japan"],
      },
      company: null,
      recently_visited: [],
      pricing_info: null,
      professional_details_info: [
        {
          id: 4,
          user: "12",
          title: "Java developer",
          years_active: '2021-02-06,"Current"',
          company_name: "PhonePe",
          skills: ["JavaScript", "Python"],
          location: "Chennai",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          annual_salary: "",
          currency: "USD",
        },
      ],
      project_details_info: [
        {
          id: 4,
          user: "12",
          project_title: "GreenScape City: Sustainable Urban Development Sim",
          role: "Lead Software Developer",
          reporting_to: "CTO",
          duration_of_project: "10",
          skills: ["HTML"],
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
      ],
      certificate_info: [
        {
          id: 4,
          user: "12",
          course_name: "UIUX",
          date_issued: "2019-06-05",
          skills: ["HTML", "JavaScript"],
          url: "www.google.com/googlecourse",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          certificate_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
          validity_date: "Life Time",
        },
      ],
      hire_info: null,
      education_info: [
        {
          id: 4,
          user: "12",
          degree: "BCA",
          year_of_graduation: "2020",
          university_name: "PSG College of Arts and Science",
          education_level: "Postgraduate Education (PG)",
          cgpa: "8",
          study_mode: "full-time",
          upload_file: [
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/image.jpg?alt=media&token=6ccbceda-dca8-4429-8964-b42d2833e14c",
          ],
        },
      ],
      video_resume:
        "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
      work_preference_info: {
        id: 4,
        key_skills: ["Tailwind CSS", "JavaScript", "Java", "Python", "React"],
        current_employment_status: "Part-time",
        preferred_mode_of_engagement: "Full Time",
        preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
        preffered_work_location: ["Singapore", "Dubai", "Europe"],
        preffered_work_timings: "13:30 to 22:30 IST",
        language: [],
        website_url: "https://web.com",
        hackerrank_url: "hackerrank.com",
        github_url: "https://github.com",
      },
      rate_card_info: {
        id: 4,
        remote_hourly: "1000",
        remote_monthly: "2000",
        remote_annualy: "1000000",
        onsite_hourly: "",
        onsite_monthly: "",
        onsite_annualy: "",
      },
      contracts_info: null,
      team_members_info: null,
      interview_info: null,
      contact_preference_info: null,
      requirements_info: null,
    },
  ],
};

export default getFacuties;
