import React from "react";
import BillingComp from "../Components/Client/ClientScreen/BillingComp/BillingComp";

const Billing = () => {
  return (
    <div>
      <BillingComp />
    </div>
  );
};

export default Billing;
