import React from "react";
import AClientProfileView from "../Components/AdminScreen/AdminProfile/AClientProfileview/AClientProfileView";

const AClientProfile = () => {
  return (
    <div>
      <AClientProfileView />
    </div>
  );
};

export default AClientProfile;
