const userData = [
  {
    id: 22,
    first_name: "Demo User",
    email: "demo@gmail.com",
    gender: "",
    username: "demo@gmail.com",
    phone: "9876543210",
    otp: "",
    title: "HR",
    linked_in: "https://www.linkedin.com/in/business-coach-coimbatore/",
    nottify: false,
    fresher: false,
    freshers_status: false,
    interview_status: "False",
    role: "2",
    date_of_birth: "",
    current_place_of_residence: "",
    lived_at_current_residence: "",
    email_verification: true,
    mobile_verification: true,
    onboarding_status: "4",
    profile_picture: "",
    part_time_availability: "",
    bio: "",
    status: "",
    apprual: false,
    employee_id: "",
    dissabled: false,
    hired_status: "",
    background_verification:
      "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
    personality_assessment:
      "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
    address: null,
    kyc_info: null,
    passport_info: null,
    preference_info: null,
    travel_info: null,
    company: {
      id: 2,
      company_email: "demo@gmail.com",
      company_name: "Demo Company",
      company_location: "India",
      company_url: null,
      company_register_no: null,
      verified: false,
      terms: true,
      plan_selected: 0,
      plan_expiry: null,
      interested_in: [
        "Full-time remote resources",
        "Part-time remote resources",
        "Onsite resources (at company office)",
      ],
      looking_for: [
        "Hire someone in 5 days",
        "Post an urgent job requirement",
        "Not sure which model will work for you? Let us call you!",
      ],
      duration: [
        "Short term project (6 months or less)",
        "6-12 months",
        "More than 12 months",
      ],
      agree_terms: true,
      notes: "Hello",
      billing_company: "Vimix Technologies LLP",
      billing_address:
        "16 Gr Flr, 5, Whispering Palms Shopping, Lokhandwala Township, Opp Mahindra & Mahindra Tractor D",
      company_pan: "11111",
      primary_name: "Demo User",
      primary_email: "demo@gmail.com",
      primary_phone: "9876543210",
      secondary_name: "",
      secondary_email: "",
      secondary_phone: "",
      pincode: "123456",
      country: "India",
    },
    recently_visited: [21],
    pricing_info: [
      {
        id: 2,
        user: "22",
        pricing_plan: "Starter",
        plan_validity: "2024-06-07",
        plan_price: "499",
        plan_duration: "Monthly",
        plan_start: "2024-05-08T10:27:02.450357Z",
        plan_status: "Paid",
        invoice_url:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
      },
    ],
    professional_details_info: [],
    project_details_info: [],
    certificate_info: [],
    hire_info: null,
    education_info: [],
    video_resume:
      "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
    work_preference_info: null,
    rate_card_info: null,
    contracts_info: null,
    team_members_info: null,
    interview_info: [
      {
        id: 4,
        user: "22",
        date: "2025-05-13",
        candidate: [
          {
            id: 5,
            first_name: "Mathesh S",
            email: "matheshsureshofficial@gmail.com",
            gender: "Male",
            username: "matheshsureshofficial@gmail.com",
            phone: "6381554238",
            otp: "927289",
            title: "null",
            linked_in: "",
            nottify: false,
            fresher: false,
            freshers_status: false,
            interview_status: "False",
            role: "3",
            date_of_birth: "1999-07-12",
            current_place_of_residence: "India",
            lived_at_current_residence: "4",
            email_verification: true,
            mobile_verification: true,
            onboarding_status: "4",
            profile_picture: "",
            part_time_availability: "",
            bio: "",
            status: "Benched",
            apprual: false,
            employee_id: "",
            dissabled: false,
            hired_status: "",
            background_verification:
              "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
            personality_assessment:
              "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
            address: {
              id: 2,
              address: "1131, National Colony Gnanagiri Road",
              city: "Sivakasi",
              state: "Tamil Nadu",
              pincode: 626189,
              country: "India",
            },
            kyc_info: {
              id: 2,
              aadhar_number: "480343957335",
              aadhar_front:
                "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
              aadhar_back:
                "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
              pan_number: "DZFPM0825L",
              pan_front:
                "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
            },
            passport_info: {
              id: 2,
              passport_number: "",
              passport_validity: "",
              country_of_citizenship: "",
              country_of_issue: "",
              passport_front: "",
              passport_back: "",
            },
            preference_info: {
              id: 2,
              qualification: "Software Engineer",
              year_of_experience: 4,
              skills: [
                "React",
                "Python",
                "Django",
                "Node js",
                "Amazon Web Services (AWS)",
              ],
              linkedin:
                "https://www.linkedin.com/in/business-coach-coimbatore/",
              hackerrank: "null",
              github: "null",
              personal_website: "null",
              language: ["English: Proficient"],
            },
            travel_info: {
              id: 2,
              travelled_to: ["India:2020:48:Site Visit:Tourist:2032-01-16"],
              travel_to_for_work: [
                "Japan:Work Onsite:6-12 months:In the next 6 months",
              ],
              relocate_for_work: "",
              willingness: "Yes",
              duration: "> 12months",
              prefered_countries: ["Japan"],
            },
            company: null,
            recently_visited: [],
            pricing_info: null,
            professional_details_info: null,
            project_details_info: null,
            certificate_info: null,
            hire_info: null,
            education_info: null,
            video_resume:
              "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
            work_preference_info: null,
            rate_card_info: null,
            contracts_info: null,
            team_members_info: null,
            interview_info: null,
            contact_preference_info: null,
            requirements_info: null,
          },
        ],
        time: "10:30",
        meeting_url: "null",
      },
    ],
    contact_preference_info: null,
    requirements_info: null,
    block_expiry: "null",
  },
];

export default userData;
