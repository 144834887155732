import React from "react";
import PricingComp from "../Components/Client/ClientSignUp/Pricing/PricingComp";

const Pricing = () => {
  return (
    <>
      <PricingComp />
    </>
  );
};

export default Pricing;
