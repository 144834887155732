/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Components/Client/ClientSignUp/SignUp/SignUpComp.css";
import Head from "../Reusable/LogoHead/Head";
import SectionHead from "../Reusable/SectionHead/SectionHead";
import { useNavigate } from "react-router-dom";
import back from "../../assests/back.png";

import axios from "axios";
import { FiEye, FiEyeOff, FiLoader } from "react-icons/fi";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const Resetpasswordcomp = () => {
  const navigate = useNavigate();
  const [isButton, setIsButton] = useState(true);

  const ButtonHandler = (e) => {
    const newPassword = e.target.value;
    if (newPassword.length !== 0) {
      settype("cpassword");
      setIsButton(false);
      setcpassworderror(false);
      setpassworderror(false);
      setpasswordmatch(false);
      const isValidLength = newPassword.length >= 6;
      const hasLowerCase = /[a-z]/.test(newPassword);
      const hasUpperCase = /[A-Z]/.test(newPassword);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
      const hasNumber = /\d/.test(newPassword);
      setValidations2({
        validLength: isValidLength,
        hasLowerCase: hasLowerCase,
        hasUpperCase: hasUpperCase,
        hasSpecialChar: hasSpecialChar,
        hasNumber: hasNumber,
      });
      if (!isValidLength) {
      } else if (!hasLowerCase) {
      } else if (!hasUpperCase) {
      } else if (!hasSpecialChar) {
      } else if (!hasNumber) {
      } else {
        setIsButton(true);
        setsignupdata((values) => ({ ...values, cpassword: e.target.value }));
        setTimeout(() => {
          settype("");
        }, 2000);
      }
    } else {
      settype("");
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);
  const showPassword = () => {
    setShow(!show);
  };

  const [show1, setShow1] = useState(false);
  const showConfirmPassword = () => {
    setShow1(!show1);
  };

  const backHandler = () => {
    navigate("/");
  };
  const [signupdata, setsignupdata] = useState({
    username: "",
    password: "",
    cpassword: "",
  });
  const [passworderror, setpassworderror] = useState(false);
  const [cpassworderror, setcpassworderror] = useState(false);
  const [passwordmatch, setpasswordmatch] = useState(false);
  const [finalerror, setfinalerror] = useState(false);
  const [type, settype] = useState("");

  const [validations1, setValidations1] = useState({
    validLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasSpecialChar: false,
    hasNumber: false,
  });
  const [validations2, setValidations2] = useState({
    validLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasSpecialChar: false,
    hasNumber: false,
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      if (value.length !== 0) {
        settype("password");
        setcpassworderror(false);
        setpassworderror(false);
        setpasswordmatch(false);
        setIsButton(false);
        const newPassword = e.target.value;
        const isValidLength = newPassword.length >= 6;
        const hasLowerCase = /[a-z]/.test(newPassword);
        const hasUpperCase = /[A-Z]/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
        const hasNumber = /\d/.test(newPassword);
        setValidations1({
          validLength: isValidLength,
          hasLowerCase: hasLowerCase,
          hasUpperCase: hasUpperCase,
          hasSpecialChar: hasSpecialChar,
          hasNumber: hasNumber,
        });
        if (!isValidLength) {
        } else if (!hasLowerCase) {
        } else if (!hasUpperCase) {
        } else if (!hasSpecialChar) {
        } else if (!hasNumber) {
        } else {
          setIsButton(true);
          setsignupdata((values) => ({ ...values, [name]: value }));
          setTimeout(() => {
            settype("");
          }, 2000);
        }
      } else {
        settype("");
      }
    } else {
      setsignupdata((values) => ({ ...values, [name]: value }));
    }
  };
  const ButtonHandler1 = async () => {
    setcpassworderror(false);
    setpassworderror(false);
    setpasswordmatch(false);
    setfinalerror(false);
    settype("");
    if (signupdata.password.length === 0) {
      setpassworderror(true);
    } else if (signupdata.cpassword.length === 0) {
      setcpassworderror(true);
      setpassworderror(false);
      setpasswordmatch(false);
    } else if (signupdata.password !== signupdata.cpassword) {
      setpasswordmatch(true);
      setcpassworderror(false);
    } else {
      setIsLoading(true);
      setpassworderror(false);
      setcpassworderror(false);
      setpasswordmatch(false);
      var newobj = {
        email: sessionStorage.getItem("email"),
        new_password: signupdata.password,
      };
      var createuser = await axios
        .put(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update_password/`,
          newobj
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (createuser.message == "Password reset successfully.") {
        sessionStorage.removeItem("email");
        setIsLoading(false);
        setTimeout(() => {
          window.location.replace("/#/login");
        }, 2000);
      }
    }
  };
  useEffect(() => {
    Checkdata();
  }, [signupdata]);
  const Checkdata = async () => {
    setIsButton(false);
    if (
      signupdata.username.length !== 0 &&
      signupdata.password.length !== 0 &&
      signupdata.cpassword.length !== 0
    ) {
      setIsButton(true);
    }
  };
  return (
    <>
      <div className="SignUpComp sectionWidth">
        <div className="clientSignUpComp">
          <div className="clientSignUpCompInner">
            <Head />
            <SectionHead head="Reset Password" desc="write your new password" />
            <div className="clientSignUpCompBody">
              <div className="clientSignUpCompBodyPassword">
                <div className="clientSignUpCompBodyPasswordLabel">
                  <h4>New Password</h4>
                </div>
                <div className="clientSignUpCompBodyPasswordInput">
                  <input
                    type={show === true ? "text" : "password"}
                    name="password"
                    onChange={handlechange}
                  />
                  {show === false ? (
                    <FiEyeOff
                      className="text-gray-500 eyeOne"
                      onClick={showPassword}
                    />
                  ) : (
                    <FiEye
                      className="text-gray-500 eyeOne"
                      onClick={showPassword}
                    />
                  )}
                </div>
                {passworderror && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Password
                  </p>
                )}
                {type === "password" ? (
                  <div className="validation-indicators mt-2">
                    <div
                      className={validations1.validLength ? "valid" : "invalid"}
                    >
                      {validations1.validLength ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}

                      {!validations1.validLength && (
                        <span className="error-message">
                          Password must be at least 6 characters long.
                        </span>
                      )}
                      {validations1.validLength && (
                        <span>
                          Password must be at least 6 characters long.
                        </span>
                      )}
                    </div>
                    <div
                      className={
                        validations1.hasLowerCase ? "valid" : "invalid"
                      }
                    >
                      {validations1.hasLowerCase ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}
                      {validations1.hasLowerCase && (
                        <span>
                          Password must contain at least one lowercase letter.
                        </span>
                      )}
                      {!validations1.hasLowerCase && (
                        <span className="error-message">
                          Password must contain at least one lowercase letter.
                        </span>
                      )}
                    </div>
                    <div
                      className={
                        validations1.hasUpperCase ? "valid" : "invalid"
                      }
                    >
                      {validations1.hasUpperCase ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}

                      {!validations1.hasUpperCase && (
                        <span className="error-message">
                          Password must contain at least one uppercase letter.
                        </span>
                      )}
                      {validations1.hasUpperCase && (
                        <span>
                          Password must contain at least one uppercase letter.
                        </span>
                      )}
                    </div>
                    <div
                      className={
                        validations1.hasSpecialChar ? "valid" : "invalid"
                      }
                    >
                      {validations1.hasSpecialChar ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}
                      {validations1.hasSpecialChar && (
                        <span>
                          Password must contain at least one special character.
                        </span>
                      )}

                      {!validations1.hasSpecialChar && (
                        <span className="error-message">
                          Password must contain at least one special character.
                        </span>
                      )}
                    </div>
                    <div
                      className={validations1.hasNumber ? "valid" : "invalid"}
                    >
                      {validations1.hasNumber ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}
                      {validations1.hasNumber && (
                        <span>Password must contain at least one number.</span>
                      )}
                      {!validations1.hasNumber && (
                        <span className="error-message">
                          Password must contain at least one number.
                        </span>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="clientSignUpCompBodyConfirmPassword">
                <div className="clientSignUpCompBodyConfirmPasswordLabel">
                  <h4>Re-enter Password</h4>
                </div>
                <div className="clientSignUpCompBodyConfirmPasswordInput">
                  <input
                    onChange={ButtonHandler}
                    type={show1 === true ? "text" : "password"}
                    name="cpassword"
                  />

                  {show1 === false ? (
                    <FiEyeOff
                      className="text-gray-500 eyeTwo"
                      onClick={showConfirmPassword}
                    />
                  ) : (
                    <FiEye
                      className="text-gray-500 eyeTwo"
                      onClick={showConfirmPassword}
                    />
                  )}
                </div>
                {passwordmatch && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Password & Re-enter Password not match
                  </p>
                )}
                {type === "cpassword" ? (
                  <div className="validation-indicators mt-2">
                    <div
                      className={validations2.validLength ? "valid" : "invalid"}
                    >
                      {validations2.validLength ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}

                      {!validations2.validLength && (
                        <span className="error-message">
                          Password must be at least 6 characters long.
                        </span>
                      )}
                      {validations2.validLength && (
                        <span>
                          Password must be at least 6 characters long.
                        </span>
                      )}
                    </div>
                    <div
                      className={
                        validations2.hasLowerCase ? "valid" : "invalid"
                      }
                    >
                      {validations2.hasLowerCase ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}
                      {validations2.hasLowerCase && (
                        <span>
                          Password must contain at least one lowercase letter.
                        </span>
                      )}
                      {!validations2.hasLowerCase && (
                        <span className="error-message">
                          Password must contain at least one lowercase letter.
                        </span>
                      )}
                    </div>
                    <div
                      className={
                        validations2.hasUpperCase ? "valid" : "invalid"
                      }
                    >
                      {validations2.hasUpperCase ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}

                      {!validations2.hasUpperCase && (
                        <span className="error-message">
                          Password must contain at least one uppercase letter.
                        </span>
                      )}
                      {validations2.hasUpperCase && (
                        <span>
                          Password must contain at least one uppercase letter.
                        </span>
                      )}
                    </div>
                    <div
                      className={
                        validations2.hasSpecialChar ? "valid" : "invalid"
                      }
                    >
                      {validations2.hasSpecialChar ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}
                      {validations2.hasSpecialChar && (
                        <span>
                          Password must contain at least one special character.
                        </span>
                      )}

                      {!validations2.hasSpecialChar && (
                        <span className="error-message">
                          Password must contain at least one special character.
                        </span>
                      )}
                    </div>
                    <div
                      className={validations2.hasNumber ? "valid" : "invalid"}
                    >
                      {validations2.hasNumber ? (
                        <FaCheckCircle />
                      ) : (
                        <FaTimesCircle />
                      )}
                      {validations2.hasNumber && (
                        <span>Password must contain at least one number.</span>
                      )}
                      {!validations2.hasNumber && (
                        <span className="error-message">
                          Password must contain at least one number.
                        </span>
                      )}
                    </div>
                  </div>
                ) : null}
                {cpassworderror && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Re-enter Password
                  </p>
                )}
              </div>
              {finalerror && (
                <p className="text-red-500 text-xs font-semibold mt-2">
                  A user with that username already exists.
                </p>
              )}
              <div className="signUpCompBodyButton marginTop20 marginBottom20">
                <button
                  onClick={ButtonHandler1}
                  id="Signup"
                  className={
                    isLoading === true
                      ? "signUpCompBodyButtonLoading"
                      : "signUpCompBodyButtonEnable"
                  }
                >
                  {isLoading === true ? (
                    <FiLoader className="loadingIcon" />
                  ) : (
                    "Change Password"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          id="page1"
          onClick={backHandler}
          className="backButtonClientReg"
        >
          <img className="back" src={back} alt="" />
          Back
        </button>
      </div>
    </>
  );
};

export default Resetpasswordcomp;
