/* eslint-disable no-unused-vars */
import React from "react";
import SignUpComp from "../Components/Client/ClientSignUp/SignUp/SignUpComp";
import CandidateSignUp from "../Components/Candidate/CandidateSignUp/SignUp/CandidateSignUp";
import NewClientFlow from "../Components/NewClientFlow/NewClientFlow";
import { useSelector } from "react-redux";

const ClientSignup = () => {
  const role = useSelector((store) => store.role);

  return (
    <>
      {/* this is old signup flow */}
      {/* {role === "Client" ? <SignUpComp /> : <CandidateSignUp />} */}
      {/* new signupFlow */}
      {role === "Client" ? <NewClientFlow /> : <CandidateSignUp />}
    </>
  );
};

export default ClientSignup;
