/* eslint-disable eqeqeq */
import React, { useRef, useState } from "react";
import "./ForgetPasswordComp.css";
import Head from "../Reusable/LogoHead/Head";
import SectionHead from "../Reusable/SectionHead/SectionHead";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { FiLoader } from "react-icons/fi";

const ForgetComp = () => {
  const navigate = useNavigate();
  const emailRef = useRef("null");
  const [email, setemail] = useState(null);
  const [isButton, setIsButton] = useState(true);
  const [finalerror, setfinalerror] = useState(null);
  const signupdata = useSelector((store) => store.signupdata);
  const userdata = useSelector((store) => store.userdata);

  const ButtonHandler = () => {
    if (emailRef.current.value.length > 3) {
      setIsButton(false);
    } else {
      setIsButton(true);
    }
    setemail(emailRef.current.value);
  };

  const [isPage, setIsPage] = useState("page1");
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorstatus, seterrorstatus] = useState(false);

  const PageHandler = async (event) => {
    if (isPage === "page2") {
      window.open("https://mail.google.com/mail/u/0/#inbox", "_blank");
    } else {
      if (isPage === "page1") {
        setfinalerror(null);
        setIsButton(true);
        var sendurl = await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/reset_password/`,
            {
              email: email,
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (sendurl.message === "OTP sent successfully.") {
          setIsPage("page2");
          sessionStorage.setItem("email", email);
          setIsButton(false);
        } else {
          setfinalerror(sendurl.email[0]);
          setIsButton(false);
        }
      } else {
        setIsPage(event.target.id);
      }
    }
  };
  const inputRefs = useRef([]);
  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length === 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if (index < inputValues.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0 && index > 0) {
      const newInputValues = [...inputValues];
      newInputValues[index] = "";
      setInputValues(newInputValues);
      inputRefs.current[index - 1].focus();
    } else if (index == 0) {
      setInputValues(["", "", "", "", "", ""]);
    }
  };

  const ButtonHandler1 = async () => {
    setIsLoading(true);
    seterrorstatus(false);
    var otp = inputValues.join("");
    var newobj = {
      email: email,
      otp: otp,
    };
    var createuser = await axios
      .post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update_password/`,
        newobj
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (createuser.message == true) {
      setIsLoading(false);
      window.location.replace("/#/resetpassword");
    } else {
      setIsLoading(false);
      seterrorstatus(true);
    }
    //
  };

  return (
    <>
      {/* ========================== page1 ================================= */}
      {isPage === "page1" && (
        <div className="forgetComp">
          <div className="clientForgetComp">
            <div className="clientForgetCompInner">
              <Head />
              <SectionHead
                head="Forgot password?"
                desc="We will send a password reset link to your email"
              />

              {/* ======================= Body ====================== */}
              <div className="clientForgetCompBody">
                <div className="clientForgetCompBodyEmail">
                  {/* ======================= Email ====================== */}
                  <h4>Email</h4>
                  <input
                    ref={emailRef}
                    onChange={ButtonHandler}
                    placeholder="you@gmail.com"
                    type="text"
                  />
                </div>
                {finalerror !== null ? (
                  <h6 className="text-red-500 text-xs font-semibold mt-2">
                    Enter a valid email address.
                  </h6>
                ) : null}

                {/* ======================= Button ====================== */}
                <div className="clientForgetCompBodyButton">
                  {isButton === true ? (
                    <button
                      className="clientForgetCompBodyButtonDisable"
                      disabled
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      id="page2"
                      onClick={PageHandler}
                      className="clientForgetCompBodyButtonEnable"
                    >
                      Submit
                    </button>
                  )}
                  <h4 onClick={() => navigate("/login")}>Back to login</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* ========================== page2 ================================= */}
      {isPage === "page2" && (
        <div className="forgotVerificationSuccess">
          <div className="forgotVerificationInner">
            <Head />
            <h1 className="verificationInnerHead marginBottom15">
              Please check your email inbox and verify the OTP.
            </h1>
            <p>
              Please enter the code that was sent to your email address.
              <span>
                {" "}
                {signupdata == null ? (
                  <span className="font-semibold">
                    {userdata.length !== 0 ? userdata[0].username : ""}.
                  </span>
                ) : (
                  <span className="font-semibold">{signupdata.username}.</span>
                )}
              </span>
            </p>
            <form className="six-digit-form">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  class="otpinput block w-[38px] h-[38px] text-center border border-gray-200 rounded-md text-sm placeholder:text-gray-300 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                />
              ))}
            </form>
            {errorstatus && (
              <p className="errorstatus">
                Sorry, the OTP you entered is incorrect. Please try again.
              </p>
            )}

            <div className="Btn marginTop15">
              <button
                id="page4"
                onClick={ButtonHandler1}
                className={isLoading === true ? "verifyBtn2" : "verifyBtn1"}
              >
                {isLoading === true ? (
                  <FiLoader className="loadingIcon" />
                ) : (
                  "Verify"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgetComp;
