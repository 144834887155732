/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./CandidateProfile.css";
import Education from "../Education/Education";
import Certificate from "../Certifications/Certificate";
import PersonalDetails from "../PersonalDetails/PersonalDetails";
import ProfessionalDetails from "../ProfessionalDetails/ProfessionalDetails";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import VideoResume from "../VideoResume/VideoResume";
import WorkExperience from "../WorkExperience/WorkExperience";
import DashHead from "../../../Reusable/DashBoardReusable/DashHead/DashHead";
import recEye from "../../../../assests/RecEye.svg";
import TravelHistory from "../../../Candidate/CandidateDashBoard/TravelHistory/TravelHistory";
import CandidateProfileCard from "../../../Reusable/CandidateProfileCard/CandidateProfileCard";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";

const CandidateProfile = () => {
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userdata);
  const token = useSelector((store) => store.token);
  const [isPage, setIsPage] = useState(false);
  const [percentage, setpercentage] = useState(0);
  const pageHandler = (event) => {
    setIsPage(!isPage);
    dispatch(storeAction.singleuserHander({ singleuser: userdata }));
  };
  useEffect(() => {
    GetPercentage();
  }, [userdata]);
  const GetPercentage = async () => {
    if (userdata.length !== 0) {
      var count = 0;
      if (userdata[0].address !== null) {
        count += 1;
      }
      if (userdata[0].work_preference_info !== null) {
        count += 1;
      }
      if (
        userdata[0].professional_details_info.length !== 0 ||
        userdata[0].fresher == true ||
        userdata[0].freshers_status == true
      ) {
        count += 1;
      }
      if (
        userdata[0].project_details_info.length !== 0 ||
        userdata[0].fresher == true ||
        userdata[0].freshers_status == true
      ) {
        count += 1;
      }
      if (userdata[0].certificate_info.length !== 0) {
        count += 1;
      }
      if (userdata[0].travel_info !== null) {
        count += 1;
      }
      if (userdata[0].education_info.length !== 0) {
        count += 1;
      }
      if (userdata[0].video_resume !== null) {
        if (userdata[0].video_resume.length !== 0) {
          count += 1;
        }
      }
      let percent = Math.round((count / 8) * 100);
      setpercentage(percent);
    }
  };
  return (
    <div>
      {/* <p></p> */}
      <div className="profilePage paddingLeft100 paddingRight100">
        {userdata.length !== 0 ? (
          percentage == 100 ? (
            <DashHead
              left=""
              center=""
              right=""
              head="Profile"
              billingId="billing"
              upgradeId="upgrade"
              desc="This is the Candidate Profile Dashboard"
              highLight=""
              button={
                userdata[0].nottify !== undefined
                  ? userdata[0].nottify == false ||
                    userdata[0].nottify.length == 0
                    ? "Preview"
                    : isPage === false
                    ? "View as Recruiter"
                    : "View as Candidate"
                  : "View as Candidate"
              }
              btnImg={recEye}
              descClass="dashBoardMainHeadDesc"
              btnClass="dashBoardMainHeadDescButton"
              fun={pageHandler}
            />
          ) : (
            <DashHead
              left=""
              center=""
              right=""
              head="Profile"
              billingId="billing"
              upgradeId="upgrade"
              desc="This is the Candidate Profile Dashboard"
              highLight=""
            />
          )
        ) : null}

        <div className="candidateProgress">
          <div className="candidateProgressHead">
            {isPage === false ? (
              <h1>Profile is completed</h1>
            ) : (
              <h1>You are now ready to get Hired-in-5!</h1>
            )}

            <h2>{percentage}%</h2>
          </div>
          {/* <div className="candidateProgressBar">
            <div className={`candidateProgressBarBackgound w-[${percentage}%]`}></div>
          </div> */}
          <div className="progress-container">
            {isPage === false ? (
              <progress
                id="file"
                value={percentage}
                max="100"
                class="progress_bar1 bg-primaryColor"
              >
                {percentage}%
              </progress>
            ) : (
              <progress
                id="file"
                value={percentage}
                max="100"
                class="progress_bar bg-primaryColor"
              >
                {percentage}%
              </progress>
            )}
          </div>
        </div>
        {isPage === false && (
          <div className="">
            <PersonalDetails />
            <WorkExperience />
            {/* <Achievement /> */}
            <ProfessionalDetails />
            <ProjectDetails />
            <Certificate />
            <TravelHistory />
            <Education />
            <VideoResume />
          </div>
        )}
        {isPage === true && (
          <CandidateProfileCard back="disNone" main="candidateProfile1" />
        )}
      </div>
    </div>
  );
};

export default CandidateProfile;
