/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Head from "../LogoHead/Head";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { storeAction } from "../../../Store/Store";
import { useNavigate } from "react-router-dom";
import { FiLoader } from "react-icons/fi";

const Emailverification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signupdata = useSelector((store) => store.signupdata);
  const userdata = useSelector((store) => store.userdata);
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);

  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setstatus] = useState(false);
  const [errorstatus, seterrorstatus] = useState(false);

  const SendMail = async () => {
    setstatus(false);
    if (userid !== null) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${userid}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      setInputValues(["", "", "", "", "", ""]);
      setstatus(true);
      setTimeout(() => {
        setstatus(false);
      }, 5000);
    }
  };

  const deleteuser = async () => {
    var deleteuser = await axios
      .delete(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    dispatch(storeAction.isloginHandler({ islogin: false }));
    dispatch(storeAction.issidebarHandler({ issidebar: false }));
    dispatch(storeAction.tokenHandler({ token: null }));
    dispatch(storeAction.useridHandler({ userid: 5 }));
    dispatch(storeAction.isPopUpHander(""));
    dispatch(storeAction.searchuserHander({ searchuser: [] }));
    dispatch(storeAction.singleuserHander({ singleuser: [] }));
    dispatch(storeAction.userdataHander({ userdata: [] }));
    dispatch(storeAction.loginroleHander({ islogin: null }));
    dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
    navigate("/signup");
  };
  const inputRefs = useRef([]);
  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length === 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if (index < inputValues.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0 && index > 0) {
      const newInputValues = [...inputValues];
      newInputValues[index] = "";
      setInputValues(newInputValues);
      inputRefs.current[index - 1].focus();
    } else if (index == 0) {
      setInputValues(["", "", "", "", "", ""]);
    }
  };
  const ButtonHandler1 = async () => {
    setIsLoading(true);
    seterrorstatus(false);
    var otp = inputValues.join("");
    var newobj = {
      email:
        signupdata == null
          ? userdata.length !== 0
            ? userdata[0].username
            : ""
          : signupdata.username,
      otp: otp,
    };
    var updateuser = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/emailverification/${userid}`,
        newobj
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (updateuser.message == "User data Updated Successfully!!!") {
      setTimeout(() => {
        setIsLoading(false);
        navigate(`/verification/${userid}`);
      }, 2000);
    } else {
      setIsLoading(false);
      seterrorstatus(true);
    }
  };
  const logoutbtn = async () => {
    dispatch(storeAction.isloginHandler({ islogin: false }));
    dispatch(storeAction.issidebarHandler({ issidebar: false }));
    dispatch(storeAction.tokenHandler({ token: null }));
    dispatch(storeAction.useridHandler({ userid: null }));
    dispatch(storeAction.isPopUpHander(""));
    dispatch(storeAction.searchuserHander({ searchuser: [] }));
    dispatch(storeAction.singleuserHander({ singleuser: [] }));
    dispatch(storeAction.userdataHander({ userdata: [] }));
    dispatch(storeAction.loginroleHander({ islogin: null }));
    dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
    dispatch(storeAction.contractdataHander({ contractdata: [] }));
    window.location.replace("/#/login");
  };
  return (
    <>
      <div className="verificationSuccess">
        <div className=" ">
          <Head />
          <h1 className="verificationInnerHead marginBottom15">
            Please check your email inbox and verify the OTP.
          </h1>
          <p>
            Please enter the code that was sent to your email address.
            <span>
              {" "}
              {signupdata == null ? (
                <span className="font-semibold">
                  {userdata.length !== 0 ? userdata[0].username : ""}.
                </span>
              ) : (
                <span className="font-semibold">{signupdata.username}.</span>
              )}
            </span>
          </p>
          {status && (
            <p className="successerror">
              The OTP has been successfully sent to your email address.
            </p>
          )}

          <form className="six-digit-form">
            {inputValues.map((value, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="number"
                maxLength="1"
                value={value}
                onChange={(event) => handleInputChange(index, event)}
                class="otpinput block w-[38px] h-[38px] text-center border border-gray-200 rounded-md text-sm placeholder:text-gray-300 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
              />
            ))}
          </form>
          {errorstatus && (
            <p className="text-red-500 text-xs font-semibold mt-2">
              Sorry, the OTP you entered is incorrect. Please try again.
            </p>
          )}

          <div className="Btn marginTop15">
            <button
              id="page4"
              onClick={ButtonHandler1}
              className={isLoading === true ? "verifyBtn2" : "verifyBtn1"}
            >
              {isLoading === true ? (
                <FiLoader className="loadingIcon" />
              ) : (
                "Verify"
              )}
            </button>
          </div>

          <div className="verificationBottom mt-5">
            <p>Check your spam folder if you can’t see an email.</p>
            <p title="" className="mt-3">
              Incorrect email?{" "}
              <span
                onClick={() => {
                  deleteuser();
                }}
              >
                Re-enter your email id{" "}
              </span>
            </p>

            <p title="" className="mt-3">
              Didn’t receive OTP?
              <span
                onClick={() => {
                  SendMail();
                }}
              >
                {" "}
                Resend
              </span>
            </p>
          </div>
          <p
            className="text-[#1D37E7] text-normal font-normal mt-3 hover:underline underline-offset-4 cursor-pointer"
            onClick={logoutbtn}
          >
            Log out
          </p>
        </div>
      </div>
    </>
  );
};

export default Emailverification;
