import React from "react";
import ACandidateProfileView from "../Components/AdminScreen/AdminProfile/ACandidateProfileView/ACandidateProfileView";

const ACandidateProfile = () => {
  return (
    <div>
      <ACandidateProfileView />
    </div>
  );
};

export default ACandidateProfile;
