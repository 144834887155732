import React from "react";
import DashBoardComp from "../Components/DashBoardComp/DashBoardComp";

const DashBoard = () => {
  return (
    <div>
      <DashBoardComp />
    </div>
  );
};

export default DashBoard;
