import React from "react";
import InterviewComp from "../Components/Client/ClientScreen/InterviewComp/InterviewComp";

const Interview = () => {
  return (
    <div>
      <InterviewComp />
    </div>
  );
};

export default Interview;
