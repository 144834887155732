const Contractdata = [
  {
    id: 1,
    user: "22",
    file: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
    uplaod_date: "2024-05-10T11:44:51.294350Z",
    name: "Non Disclosure Agreement (NDA)",
    date: "",
    duration: "",
    billing_cycle: "",
    candidate: null,
  },
  {
    id: 2,
    user: "22",
    file: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
    uplaod_date: "2024-05-10T11:45:25.342815Z",
    name: "Statement of Work (SOW)",
    date: "2024-05-10",
    duration: "3 Months",
    billing_cycle: "Monthly",
    candidate: "21",
    Candidate: [
      {
        id: 21,
        first_name: "Dinesh Dk",
        email: "dinesh@vimix.app",
        gender: "Male",
        username: "dinesh@vimix.app",
        phone: "8056521461",
        otp: "410369",
        title: "null",
        linked_in: "",
        nottify: true,
        fresher: true,
        freshers_status: true,
        interview_status: "False",
        role: "3",
        date_of_birth: "2024-04-29",
        current_place_of_residence: "India",
        lived_at_current_residence: "2",
        email_verification: true,
        mobile_verification: true,
        onboarding_status: "4",
        profile_picture: "",
        part_time_availability: "",
        bio: "",
        status: "Hired",
        apprual: false,
        employee_id: "",
        dissabled: false,
        hired_status: "",
        background_verification:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
        personality_assessment:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/dummy-pdf_2.pdf?alt=media&token=ea78a49a-7717-4371-92ec-57319d187fcf",
        address: {
          id: 11,
          address: "2/326 A KUMARAN STREET",
          city: "Coimbatore",
          state: "Tamil Nadu",
          pincode: 641302,
          country: "India",
        },
        kyc_info: {
          id: 11,
          aadhar_number: "11111",
          aadhar_front:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/images%20(1).jpeg?alt=media&token=af37298b-88ac-4913-91bf-a2523228a9b5",
          aadhar_back:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Sample_PVC_Aadhar_Card_back.jpg?alt=media&token=d6cbf56b-9892-4b78-83f8-e1ba33489912",
          pan_number: "ADRDD7856D",
          pan_front:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/A_sample_of_Permanent_Account_Number_(PAN)_Card.jpg?alt=media&token=4fb771d6-9cf7-49be-b707-fa3d93933528",
        },
        passport_info: {
          id: 11,
          passport_number: "",
          passport_validity: "",
          country_of_citizenship: "",
          country_of_issue: "",
          passport_front: "",
          passport_back: "",
        },
        preference_info: {
          id: 11,
          qualification: "React Developer",
          year_of_experience: 10,
          skills: ["React", "JavaScript", "React Js", "React Native", "Figma"],
          linkedin: "https://linkedin.com",
          hackerrank: "50",
          github: "https://github.com/john",
          personal_website: "https://test.com",
          language: ["Tamil: Native"],
        },
        travel_info: {
          id: 11,
          travelled_to: ["India:2023:10:Work:H-1B:2024-05-25"],
          travel_to_for_work: [
            "Japan:Work Onsite:3-6 months:Immediate",
            "Dubai:Short-term business visit:6-12 months:In the next 6 months",
          ],
          relocate_for_work: "",
          willingness: "Yes",
          duration: "6-12 months",
          prefered_countries: ["Japan", "Dubai"],
        },
        company: null,
        recently_visited: [],
        pricing_info: null,
        professional_details_info: null,
        project_details_info: null,
        certificate_info: null,
        hire_info: {
          id: 1,
          candidate_id: "21",
          company_name: "Demo Company",
          client_id: "22",
          contract_id: "",
        },
        education_info: null,
        video_resume:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/WhatsApp%20Video%202024-05-31%20at%2017.40.09_761c43fa.mp4?alt=media&token=c8391d8e-430d-49fc-9ffb-83bd71581c2d",
        work_preference_info: {
          id: 10,
          key_skills: [
            "React",
            "JavaScript",
            "React Js",
            "React Native",
            "Figma",
          ],
          current_employment_status: "Full-time",
          preferred_mode_of_engagement: "Part-time",
          preferred_method_of_work: ["Remote", "Hybrid", "Work From Office"],
          preffered_work_location: ["Japan", "Singapore", "Malaysia"],
          preffered_work_timings: "10:30 to 19:30 IST",
          language: [],
          website_url: "https://test.com",
          hackerrank_url: "https://hackerrank.com",
          github_url: "https://github.com/john",
        },
        rate_card_info: null,
        contracts_info: null,
        team_members_info: null,
        interview_info: null,
        contact_preference_info: null,
        requirements_info: null,
      },
    ],
  },
];
export default Contractdata;
