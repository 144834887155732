import React from "react";
import AdminLogin from "../Components/AdminScreen/AdminLogin/AdminLogin";

const AdminSignUp = () => {
  return (
    <div>
      <AdminLogin />
    </div>
  );
};

export default AdminSignUp;
