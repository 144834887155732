import React from "react";
import UpgradeComp from "../Components/Client/ClientScreen/BillingComp/UpgradeComp/UpgradeComp";

const Upgrade = () => {
  return (
    <div>
      <UpgradeComp />
    </div>
  );
};

export default Upgrade;
