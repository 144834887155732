const Loginuser = [
  {
    email: "demo@gmail.com",
    password: "Demo@333",
  },
  {
    email: "test@gmail.com",
    password: "Test@333",
  },
  {
    email: "Demo@gmail.com",
    password: "Demo@333",
  },
  {
    email: "Test@gmail.com",
    password: "Test@333",
  },
  {
    email: "connect@hirein5.com",
    password: "Nepal@!2345",
  },
  {
    email: "Connect@hirein5.com",
    password: "Nepal@!2345",
  },
];
export default Loginuser;
