import React from "react";
import Resetpasswordcomp from "../Components/Resetpassword/Resetpasswordcomp";

export const Resetpassword = () => {
  return (
    <div className="sectionWidth">
      <Resetpasswordcomp />
    </div>
  );
};
