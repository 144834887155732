import React from "react";
import AdminHome from "../Components/AdminScreen/AdminHome/AdminHome";

const AdminHomePage = () => {
  return (
    <div>
      <AdminHome />
    </div>
  );
};

export default AdminHomePage;
