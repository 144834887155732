import React from 'react'
import AdminContract from '../Components/AdminScreen/AdminContract/AdminContract'

const AdminContractCards = () => {
  return (
    <>
    <AdminContract />
    </>
  )
}

export default AdminContractCards