/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import "./SideBar.css";
import logout from "../../../assests/logout.png";
import logo from "../../../assests/Logo.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import back from "../../../assests/billingX.png";
import { RxCross1 } from "react-icons/rx";
import ProgressBar from "../../PrelineComponent/ProgressBar/Circlebar";
import { useLocation } from "react-router-dom";
import moment from "moment";
import userData from "../../../HireIn5 json/userData";

const SideBar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const userdata = useSelector((store) => store.userdata);
  const loginrole = useSelector((store) => store.loginrole);

  useEffect(() => {
    setTimeout(() => {
      getUserinfo();
    }, 1000);
  }, [token, userid, loginrole]);

  const getUserinfo = useCallback(async () => {
    if (loginrole == 2) {
      setIsHover("discover");
    } else if (loginrole == 3) {
      setIsHover("profile");
    } else {
      setIsHover("adminHome");
    }
    if (token !== null && userid !== null) {
      // var userinfo = await axios
      //   .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}`, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `JWT ${token}`,
      //     },
      //   })
      //   .then((res) => {
      //     return res.data;
      //   })
      //   .catch((err) => {
      //     if (err.response.status === 401) {
      //       dispatch(storeAction.isloginHandler({ islogin: false }));
      //       dispatch(storeAction.issidebarHandler({ issidebar: false }));
      //       dispatch(storeAction.tokenHandler({ token: null }));
      //       dispatch(storeAction.useridHandler({ userid: null }));
      //       dispatch(storeAction.isPopUpHander(""));
      //       dispatch(storeAction.searchuserHander({ searchuser: [] }));
      //       dispatch(storeAction.singleuserHander({ singleuser: [] }));
      //       dispatch(storeAction.userdataHander({ userdata: [] }));
      //       dispatch(storeAction.loginroleHander({ islogin: null }));
      //       dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
      //       dispatch(storeAction.contractdataHander({ contractdata: [] }));
      //       window.location.replace("/#/login");
      //     } else {
      //       dispatch(storeAction.isloginHandler({ islogin: false }));
      //       dispatch(storeAction.issidebarHandler({ issidebar: false }));
      //       dispatch(storeAction.tokenHandler({ token: null }));
      //       dispatch(storeAction.useridHandler({ userid: null }));
      //       dispatch(storeAction.isPopUpHander(""));
      //       dispatch(storeAction.searchuserHander({ searchuser: [] }));
      //       dispatch(storeAction.singleuserHander({ singleuser: [] }));
      //       dispatch(storeAction.userdataHander({ userdata: [] }));
      //       dispatch(storeAction.loginroleHander({ islogin: null }));
      //       dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
      //       dispatch(storeAction.contractdataHander({ contractdata: [] }));
      //       window.location.replace("/#/login");
      //     }
      //     return err.response;
      //   });
      // if (userinfo !== undefined) {
      //   if (userinfo.id !== undefined) {
      dispatch(storeAction.userdataHander({ userdata: userData }));
      //   } else {
      //     if (userinfo.data.code == "token_not_valid") {
      //       dispatch(storeAction.isloginHandler({ islogin: false }));
      //       dispatch(storeAction.issidebarHandler({ issidebar: false }));
      //       dispatch(storeAction.tokenHandler({ token: null }));
      //       dispatch(storeAction.useridHandler({ userid: null }));
      //       dispatch(storeAction.isPopUpHander(""));
      //       dispatch(storeAction.searchuserHander({ searchuser: [] }));
      //       dispatch(storeAction.singleuserHander({ singleuser: [] }));
      //       dispatch(storeAction.userdataHander({ userdata: [] }));
      //       dispatch(storeAction.loginroleHander({ islogin: null }));
      //       dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
      //       dispatch(storeAction.contractdataHander({ contractdata: [] }));
      //       window.location.replace("/#/login");
      //     }
      //   }
      // }
    } else {
      dispatch(storeAction.isloginHandler({ islogin: false }));
      dispatch(storeAction.issidebarHandler({ issidebar: false }));
      dispatch(storeAction.tokenHandler({ token: null }));
      dispatch(storeAction.useridHandler({ userid: null }));
      dispatch(storeAction.isPopUpHander(""));
      dispatch(storeAction.searchuserHander({ searchuser: [] }));
      dispatch(storeAction.singleuserHander({ singleuser: [] }));
      dispatch(storeAction.userdataHander({ userdata: [] }));
      dispatch(storeAction.loginroleHander({ islogin: null }));
      dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
      dispatch(storeAction.contractdataHander({ contractdata: [] }));
      window.location.replace("/#/login");
    }
    var getCompanies = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (getCompanies.companies !== undefined) {
      if (getCompanies.companies.length !== 0) {
        for (var i = 0; i < getCompanies.companies.length; i++) {
          if (getCompanies.companies[i].pricing_info.length !== 0) {
            const latestDateObject = getCompanies.companies[
              i
            ].pricing_info.reduce((latest, current) => {
              return new Date(current.plan_start) > new Date(latest.plan_start)
                ? current
                : latest;
            });
            var today = moment();
            var planValidity = moment(latestDateObject.plan_validity);
            var diffInDays = planValidity.diff(today, "days");
            var newobj = {
              email: getCompanies.companies[i].username,
              subject: `Renewal Payment Due for ${latestDateObject.pricing_plan}!`,
              message: `We wanted to remind you that your subscription for ${latestDateObject.pricing_plan} is due for renewal soon. As valued members of our community, we appreciate your continued support.`,
            };
          }
        }
      }
    }
  }, [token, userid, loginrole]);
  const logoutbtn = () => {
    dispatch(storeAction.isloginHandler({ islogin: false }));
    dispatch(storeAction.issidebarHandler({ issidebar: false }));
    dispatch(storeAction.tokenHandler({ token: null }));
    dispatch(storeAction.useridHandler({ userid: null }));
    dispatch(storeAction.isPopUpHander(""));
    dispatch(storeAction.searchuserHander({ searchuser: [] }));
    dispatch(storeAction.singleuserHander({ singleuser: [] }));
    dispatch(storeAction.userdataHander({ userdata: [] }));
    dispatch(storeAction.loginroleHander({ islogin: null }));
    dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
    dispatch(storeAction.contractdataHander({ contractdata: [] }));
    window.location.replace("/#/login");
  };

  const [isHover, setIsHover] = useState(
    "discover" || "profile" || "adminHome"
  );
  const HoverHandler = (e) => {
    navigate(e.target.id);
    setIsHover(e.target.id);
  };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const overLayHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const exitOverlayHandler = () => {
    dispatch(storeAction.isPopUpHander());
  };
  const [valueper, setvalueper] = useState(0);

  return (
    <div>
      <div className="sideNav">
        <div className="sideNavInner">
          <div className="sideNavTop">
            <div
              onClick={() => navigate("/discover")}
              className="sideNavHead marginBottom15"
            >
              <img className="marginTop15" src={logo} alt="" />
            </div>
            <div className="dashProfile">
              <div className="profileName">
                {userdata.length !== 0 ? (
                  <h2>
                    {userdata[0].first_name.length !== 0
                      ? userdata[0].first_name
                      : "Admin"}
                  </h2>
                ) : null}
                {loginrole == 2 ? (
                  userdata.length !== 0 ? (
                    userdata[0].company !== null ? (
                      <p>{userdata[0].company.company_name}</p>
                    ) : null
                  ) : null
                ) : loginrole == 3 ? (
                  <p>Candidate</p>
                ) : (
                  <p>HireIn5</p>
                )}
              </div>
              <div className="profilePic">
                {userdata.length !== 0 ? (
                  userdata[0].profile_picture !== null ? (
                    userdata[0].profile_picture.length !== 0 ? (
                      <img src={userdata[0].profile_picture} alt="" />
                    ) : (
                      <Avatar
                        name={userdata[0].first_name}
                        size={50}
                        round="50px"
                      />
                    )
                  ) : (
                    <Avatar
                      name={userdata[0].first_name}
                      size={50}
                      round="50px"
                    />
                  )
                ) : null}
              </div>
            </div>
            <div className="navMenu">
              {props.menu.map((data) => {
                if (data.router == location.pathname.slice(1)) {
                  return (
                    <div
                      onClick={HoverHandler}
                      id={data.router}
                      className="menu1Active"
                    >
                      <span id={data.router} className="menuIcon" alt="">
                        {data.icon}
                      </span>
                      <h4 id={data.router} className="menuName">
                        {data.title}
                      </h4>
                      {/* <p className="number">5</p> */}
                    </div>
                  );
                } else {
                  return (
                    <div
                      id={data.router}
                      onClick={HoverHandler}
                      className="menu1"
                    >
                      <span id={data.router} className="menuIcon" alt="">
                        {data.icon}
                      </span>
                      <h4 id={data.router} className="menuName">
                        {data.title}
                      </h4>
                      {/* <p className="number">5</p> */}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          {loginrole == 3 ? (
            <div className="sidebarProgressBar">
              <div className="sidebarProgressBarImg">
                <ProgressBar setvalueper={setvalueper} />
              </div>
              {valueper != 100 ? null : (
                <h1>
                  <span className="text-green-600">100%</span> Profile is
                  completed
                </h1>
              )}
              <p>Your profile is submitted for review</p>
              {valueper != 100 ? (
                <h2
                  onClick={() => {
                    window.location.replace("/#/profile");
                  }}
                >
                  Complete my profile
                </h2>
              ) : null}
            </div>
          ) : null}
        </div>
        <div id="logoutPopUp" className="logout" onClick={overLayHandler}>
          <img id="logoutPopUp" src={logout} alt="" onClick={overLayHandler} />
          <h6 id="logoutPopUp" onClick={overLayHandler}>
            Log out
          </h6>
        </div>
        {isPopUp == "logoutPopUp" && (
          <div className="logoutPopUp">
            <div className="logoutPopUpHead">
              <h1>Logout</h1>
              <img onClick={exitOverlayHandler} src={back} alt="" />
            </div>
            <h2>
              Are you sure you want to logout? You’ll have to log in again to
              use this platform
            </h2>
            <button onClick={logoutbtn} className="logoutActiveButton">
              Yes, logout
            </button>
            <button
              onClick={exitOverlayHandler}
              className="logoutInaciveButton"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      <div onClick={overLayHandler} className="IconClose">
        <RxCross1 />
      </div>
    </div>
  );
};

export default SideBar;
