import React from "react";
import AdminTeamMemberComp from "../Components/AdminScreen/AdminTeamMember/AdminTeamMemberComp";

const AdminTeamMember = () => {
  return (
    <>
      <AdminTeamMemberComp />
    </>
  );
};

export default AdminTeamMember;
